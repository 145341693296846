:root {
  --text: #fff;
  --bg: #0a4b8d;
  --headerBoxShadow: rgb(0 0 0 / 15%) 0 7px 5px;
}

body.light {
  --text: #fff;
  --bg: #0a4b8d;
  --headerBoxShadow: rgb(0 0 0 / 15%) 0 7px 5px;
}
.logo {
  max-height: 170px;
  display: inline-block;
  width: auto;
}
